.subHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.form {
    margin-bottom: 2rem;
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
}
