@use "src/shared/styles/variables" as *;

.contentContainer {
  margin: 20px 0;
  width: $xxl;
}

.mainLayoutContainer {
  min-height: 100vh !important;
}

.content {
  margin-top: 64px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: $sm) {
  .contentContainer {
    width: $sm;
  }
}
@media (min-width: $md) {
  .contentContainer {
    width: $md;
  }
}
@media (min-width: $lg) {
  .contentContainer {
    width: $lg;
  }
}
@media (min-width: $xl) {
  .contentContainer {
    width: $xl;
  }
}
@media (min-width: $xxl) {
  .contentContainer {
    width: $xxl;
  }
}

