.container {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: .4s;
  &:hover {
    background: #1890ff;
    color: #fff;
  }

  p {
    margin-bottom: 0;
  }
}
