@use "src/shared/styles/variables" as *;

.container {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $secondary-white;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  position: absolute;
}

.thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.name {
  font-size: 11px;
  text-align: center;
}

.position {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.endScreenNumber {
  position: absolute;
  top: -8px;
  font-size: 60px;
  opacity: .15;
  z-index: 1;
  text-align: center;
  width: 100%;
}
.endScreenText {
  position: relative;
  text-align: center;
  z-index: 2;
  text-shadow: 0px 1px white;
}

