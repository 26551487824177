@use "src/shared/styles/variables" as *;

.container {
  background: $grayLighter;
  padding: 16px;
  display: flex;
  gap: 15px;
  flex-grow: 1;
  flex-basis: 280px;
}

.score {
  font-weight: 700;
  font-size: 34px;
  line-height: 1;
}

.scoreDescription {
  display: flex;
  align-items: center;
  gap: 4px;
  grid-template-columns: min-content 1fr;
  font-size: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.box {
  flex-basis: 108px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-shrink: 0;
}
