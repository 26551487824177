@use "src/shared/styles/variables" as *;

.container {
  display: flex;
  align-items: center;
}

.title {
  white-space: nowrap;
  margin: 0 40px 0 0 !important;
}

.processed {
  margin-right: 40px;
}

.info {
  padding: 10px;
  border-radius: 7px;
  background: $secondary-black-tint;
  width: 100%;
}
