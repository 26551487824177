@use "src/shared/styles/variables" as *;

.container {
  min-width:20%;
  flex-basis:30%;
  flex-grow: 1;
  max-width:50%;
}

.title {
  padding: 10px;
  background: $grayLighter;
}
