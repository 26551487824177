.headerContainer,
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roundedButton {
  border-radius: 6px !important;
  padding: 10px 30px !important;
  height: auto !important;
}
.roundedButtonLeft {
  border-radius:  10px 0 0 10px !important;
}
.roundedButtonRight {
  border-radius:  0 10px 10px 0 !important;
}

.radioButton {
  padding: 5px 30px !important;
  height: auto !important;

}

.buttonWithMargin {
  margin-right: 15px;
}
.typeConatainer {
  width: 70vw;
}
.link {
  margin-bottom: 0.5rem;
}

.campaignDatesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.endDate {
  margin-left: 15px;
}

.campaignTableContainer {
  flex: 2;
}

.campaignClicksInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.figuresSection {
  padding-top: 44px;
  padding-left: 40px;
  align-self: center;
}

.figuresContainer {
  flex: 1;
  line-height: 1;
}

.figure {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 30px;
}

.figureValueContainer,
.figureCaptionContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.figureValue {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin-bottom: 0;
  
}

.figureCaption {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 0;

}

.graphControlsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.reportsContainer {
  margin: 40px 0;
}
