@use "src/shared/styles/variables" as *;

.container {
  position: relative;
  display: flex;
  justify-content: center;
}

.containerOuter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  background: transparent;
  height: 270px;
  transform: translate(0px, -20px);
}

.containerInner {
  position: relative;
  width: 458px;
  background: $secondary-black-tint;
  height: 203px;
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, .2)
}

.containerLimiter {
  background: #f0f2f5;
  height: 10px;
  border-bottom: 1px solid rgba(0,0,0,.08);
  margin-top: -1px;
}

.errorMessage {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(255, 0, 0, .5);
  z-index: 1;
  padding: 8px;
  pointer-events: none;
}
