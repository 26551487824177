.innerContainer {
  display: flex;
  flex-direction: column;
  .horizontal {
    flex-direction: row !important;
    align-items: baseline;
    .ant-form-item-label {
      text-align: right;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.justify {
  justify-content: center;
}


.dates {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 30px 0;
}

.buttons {
  display: flex;
  gap: 20px;
}

.status {
  margin-bottom: 0 !important;
}
