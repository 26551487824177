.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.tag {
  margin-right: 10px;

  p {
    margin-bottom: 0;
  }
}
