@use "src/shared/styles/variables" as *;

/*
Basic mixin for generating media requests
Use: @include for-size(sm) {
      width: 100%;
   }
*/

@mixin for-size($size) {
  @if $size == sm {
    @media (min-width: $sm) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: $md) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: $lg) {
      @content;
    }
  } @else if $size == xl {
    @media (min-width: $xl) {
      @content;
    }
  } @else if $size == xxl {
    @media (min-width: $xxl) {
      @content;
    }
  }
}
