@use "src/shared/styles/mixins/for-size" as *;

.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @include for-size(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.elementsItem {
  position: relative;
  width: 150px;
  height: 80px;
}

.row {
  cursor: pointer;
}

.actions {
  width:80px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
}

.smallEndScreen {
  padding: 0;
  animation: scale 1ms ease-in 0s 1 normal forwards;
  position: relative;
  top: -90px;
}

@keyframes scale {
  from {transform: scale(1)}
  to {transform: scale(.3)}
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:24px;
  justify-content: center;
}
