@use "src/shared/styles/variables" as *;

.container {
  background: $secondary-white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 2px rgba(0,0,0,.2);
  position: absolute;
}

.thumbnail {
  width: 100%;
  height: 100%;
}

.name {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 20px;
  padding: 6px;
  font-weight: 600;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
}

.containerInner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.containerForPosition {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.type_name {
  text-align: center;
}

.endScreenNumber {
  position: absolute;
  top: -8px;
  font-size: 60px;
  opacity: .15;
  z-index: 1;
}
.endScreenText {
  position: relative;
  z-index: 2;
  text-shadow: 0px 1px white;
}

