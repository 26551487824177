@import '~react-toastify/dist/ReactToastify.css';
@import '~antd/dist/antd.css';

body {
  font-family: 'Arial', sans-serif;
}

table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.site-panel {
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;

  &:first-child {
    margin-top: 50px;
  }
}

.mcn-pagination {
  & > .ant-pagination-total-text {
    margin-right: auto;
  }

  & > .ant-pagination-options {
    margin-left: auto;
  }
}

.ant-form-vertical .ant-form-item-label {
  text-align: right;
  margin-right: 8px;
  padding: 0 !important;
  justify-content: center;
  display: flex;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-table {
  font-size: 14px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 16px 8px;
}
