.container {
 display: flex;
 width: calc(100% - 22px);
 justify-content: space-between;
 height: 35px;
 box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 20%);
}

.leftContainer {
 display: flex;
 width: 30%;
}
