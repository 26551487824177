.container {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 20px;
}

.innerContainer {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
}
