.form {
    label {
      width: 80px;
    }
  .item {
    margin-bottom: 10px;
  }
  .token {
    margin-bottom: 25px;
  }
}

.instructionButton {
  margin-bottom: 30px;
}

.instruction {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  border-radius: 32px;
}
