.endscreen-actions-column {
    padding: 0 !important;
}

.endscreen-url-column {
    max-width: 150px;
    // white-space: pre;
    // overflow: hidden;
}

.flexCell {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 32px;
    margin: 0;
}
