.container {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.endScreenNumber {
  position: absolute;
  top: 0;
  font-size: 60px;
  opacity: .15;
  z-index: 1;
}
.endScreenText {
  position: relative;
  z-index: 2;
  text-shadow: 0px 1px white;
}
