.row {
  display: flex;
  gap: 20px;
  justify-content: center;
  .child {
    flex: 1
  }
  .channel {
    flex: 1.1
  }
  .checkbox {
    flex: 1;
    transform: translate(35px, 0px);
  }
}

.col {
  max-width: 250px;
}

.button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

