.columnHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  line-height: 1.2em;
}

.icon {
  color: #c3c4c3;
  cursor: pointer;
}
