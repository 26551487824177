@use "src/shared/styles/variables" as *;

.heading {
  margin-left: 16px;
  margin-bottom: 16px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.controlsWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "tags tags"
    "date range";
  gap: 20px;
}

.searchWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-areas:
    "tags tags"
    "date range";
  gap: 20px;
}

.tags {
  grid-area: tags;
  flex-direction: row !important;
}

.searchButton {
  display: flex;
  justify-content: flex-end;
}

.date {
  grid-area: date;
  flex-direction: row !important;
  align-items: center;
  .label {
    padding: 0
  }
}

.rangePickerClassName {
  width: 100%;
}

.dateRange {
  grid-area: range;
  flex-direction: row !important;
  align-items: center;
}
