.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.selectContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
}

.uploadContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.heading {
    width: 100%;
    margin-bottom: 10px;
}

.footerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0 0 0;
}

.errorContainer {
    color: red;
    margin-bottom: 10px;
    width: 100%;
}
