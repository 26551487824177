/*
 Breakpoint  Class infix  Dimensions
 X-Small  None  <576px
 Small  sm  ≥576px
 Medium  md  ≥768px
 Large  lg  ≥992px
 Extra large  xl  ≥1200px
 Extra extra large  xxl  ≥1400px
*/
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// GRAYSCALE
$transparent: transparent;
$white: #ffffff;
$areaBackground: #f4f4f4;
$grayLighter: #e5e5e5;
$grayLight: #cbcbcb;
$gray: #929292;
$grayDark: #646464;
$grayDarker: #383838;
$black: #000000;

$text-white: $white;
$text-black: $black;
$text-not-active: $grayDark;
$text-disabled: $gray;

// SECONDARY-BLACK
$secondary-black: $grayDarker;
$secondary-black-tint: $areaBackground;
$secondary-black-inverse: $white;
// PROGRAMMATIC
$secondary-black-hover: $black;
$secondary-black-focus: $black;

// SECONDARY-WHITE
$secondary-white: $white;
$secondary-white-tint: $grayDarker;
$secondary-white-inverse: $black;
// PROGRAMMATIC
$secondary-white-hover: $grayLighter;
$secondary-white-focus: $grayLighter;
$secondary-white-hover-outline: $grayDarker;

// SUCCESS
$success: #008642;
$success-tint: #d7f0e3;
$success-inverse: $white;
// WARNING
$warning: #db7b0b;
$warning-tint: #ffecd4;
$warning-inverse: $white;
// DANGER
$danger: #d72e3d;
$danger-tint: #fedddd;
$danger-inverse: $white;
// INFO
$info: #3f3f3f;
$info-tint: #ececec;
$info-inverse: $white;
// INNER BORDER COLORS
$innerBorderBlue: #0032a0;
$innerBorderRed: #d72e3d;
