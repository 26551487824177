@use "src/shared/styles/variables" as *;

.wrapper {
  position: fixed;
  width: 100%;
  background: $white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 0 !important;
}

.wrapperInner {
  width: $xxl;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.googleLoginButton {
  height: 100%;
  margin-right: 10px;

  div {
    padding: 0 10px !important;
    display: flex;
    align-items: center;
  }

  span {
    padding: 0 10px 0 0 !important;
  }
}

.rightMenuContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.logoLink {
  font-weight: 700;
  font-size: 24px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 24px;
  flex: 0.39;
}

.menuDropdown {
  &:hover {
    cursor: pointer;
  }
}

.menu {
  height: 60px;
}

.menuItem {
  display: flex !important;
  align-items: center;
}


@media (min-width: $sm) {
  .wrapperInner {
    width: $sm;
  }
}
@media (min-width: $md) {
  .wrapperInner {
    width: $md;
  }
}
@media (min-width: $lg) {
  .wrapperInner {
    width: $lg;
  }
}
@media (min-width: $xl) {
  .wrapperInner {
    width: $xl;
  }
}
@media (min-width: $xxl) {
  .wrapperInner {
    width: $xxl;
  }
}
