.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  font-size: 12px;
  text-align: center;
  line-height: 1;
  text-decoration: underline;
  cursor: pointer;
}

.iconButton {
  font-size: 24px !important;
  margin-bottom: 5px;
}

.actionsRight {
  display: flex;
  align-items: center;
  gap: 8px;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
  margin: 10px 0 5px 0;
  padding: 0 !important;
}
